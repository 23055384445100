import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { StorageService } from "src/app/core/services/storage.service";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AbhaService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private storageService: StorageService
  ) {}
  generateOTP(aadhar: string) {
    let submitData = {
      "aadhaar": aadhar,
    };
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    };
    return this.http.post(
      environment.apiEnrolUrl + "/truecover/abha/generateOtp",
      submitData,
      httpOptions
    );
  }
  verifyOTP(submitData: any) {
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    };
    return this.http.post(
      environment.apiEnrolUrl + "/truecover/abha/verifyOTP",
      submitData,
      httpOptions
    );
  }
  generateMobileOtp(submitData: any) {
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    };
    return this.http.post(
      environment.apiEnrolUrl + "/truecover/abha/generateMobileOtp",
      submitData,
      httpOptions
    );
  }
  verifyMobileOTP(submitData: any) {
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    };
    return this.http.post(
      environment.apiEnrolUrl + "/truecover/abha/verifyMobileOTP",
      submitData,
      httpOptions
    );
  }
  generateLoginMobileOtp(submitData: any) {
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    };
    return this.http.post(
      environment.apiEnrolUrl + "/truecover/abha/generateOtpForMobile",
      submitData,
      httpOptions
    );
  }
  verifyLoginMobileOTP(submitData: any) {
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    };
    return this.http.post(
      environment.apiEnrolUrl + "/truecover/abha/verifyOtpForMobile",
      submitData,
      httpOptions
    );
  }
  generateHealthIdByAadhaar(submitData: any) {
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    };
    return this.http.post(
      environment.apiEnrolUrl + "/truecover/abha/generateHealthIdByAadhaar",
      submitData,
      httpOptions
    );
  }

  initiateProcessForHealthCard(submitData: string) {
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    };
    return this.http.post(
      environment.apiEnrolUrl + "/truecover/abha/initiateProcessForHealthCard",
      submitData,
      httpOptions
    );
  }

  confirmWithAadharOtpForHealth(submitData: string) {
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    };
    return this.http.post(
      environment.apiEnrolUrl + "/truecover/abha/confirmWithAadharOtpForHealth",
      submitData,
      httpOptions
    );
  }

  abhaQrCode(xtoken:any) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json'})
    };
    return this.http.post(
      environment.apiEnrolUrl + "/truecover/abha/getQrCode",
      xtoken,
      httpOptions
    );   
  }
  
  getAllAbhaUser(submitData: any){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json'})
    };
    return this.http.post(
      environment.apiEnrolUrl + "/truecover/abha/getAbhaUsers",
      submitData,
      httpOptions
    );   
  }
  getUser(submitData: any){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json'})
    };
    return this.http.post(
      environment.apiEnrolUrl + "/truecover/abha/getUser",
      submitData,
      httpOptions
    );   
  }
  getPreSignedUrlByKey(bucket, key) {
    const param = new HttpParams().set('bucket', bucket)
      .set('key', key);
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params: param
    };
    return this.http.get(environment.apiEnrolUrl + '/truecover/s3/get-pre-signed-url-by-key', httpOptions);
  }
  getIdCard(submitData: any){    
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json'})
    };
    return this.http.post(
      environment.apiEnrolUrl + "/truecover/abha/getHealthCardPdf",
      submitData,
      httpOptions
    ); 
  }
  generatePhrAddress(xtoken:any){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json'})
    };
    return this.http.post(
      environment.apiEnrolUrl + "/truecover/abha/generatePhrAddress",
      xtoken,
      httpOptions
    );  
  }

  searchAbha(submitData: any) {
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    };
    return this.http.post(
      environment.apiEnrolUrl + "/truecover/abha/searchAbhaUser",
      submitData,
      httpOptions
    );
  }
  getAbhaMember() {
    return this.http.get(environment.apiEnrolUrl + '/truecover/abha/getAbhaMember');
  }
  getAbhaAccountDetails() {
    return this.http.get(environment.apiEnrolUrl + '/truecover/abha/getAccountInformation');
  }
  getAbhaLogin(submitData:any) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json'})
    };
    return this.http.post(
      environment.apiEnrolUrl + "/truecover/abha/getAbhaLogin",
      submitData,
      httpOptions
    ); 
  }
  verifyLoginOTPAadhar(submitData: any) {
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    };
    return this.http.post(
      environment.apiEnrolUrl + "/truecover/abha/setAbhaLoginAadhar",
      submitData,
      httpOptions
    );
  }
  verifyLoginOTPMobile(submitData: any) {
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    };
    return this.http.post(
      environment.apiEnrolUrl + "/truecover/abha/setAbhaLoginMobile",
      submitData,
      httpOptions
    );
  }
}
