import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EncryptService {

constructor() { }

generateEncryption(value: any) {
  const keySize = 256;
  const iterations = 100;
  const salt = CryptoJS.lib.WordArray.random(128 / 8);
  const key = CryptoJS.PBKDF2('vote for NDM', salt, {
    keySize: keySize / 32,
    iterations: iterations
  });
  const iv = CryptoJS.lib.WordArray.random(128 / 8);
  const encryptedValue = CryptoJS.AES.encrypt(value, key, {
    iv: iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC
  });
  const encryption = salt.toString() + iv.toString() + encryptedValue.toString();
  return encryption.toString();
}
stringEncryption(value: any, encToken: any) {
  const keySize = 256;
  const iterations = 100;
  const salt = CryptoJS.lib.WordArray.random(128 / 8);
  const newEnc = CryptoJS.enc.Utf8.parse(encToken);
  const key = CryptoJS.PBKDF2(newEnc, salt, {
    keySize: keySize / 32,
    iterations: iterations
  });
  const iv = CryptoJS.lib.WordArray.random(128 / 8);
  const newVal =  JSON.stringify(value);
  const encryptedValue = CryptoJS.AES.encrypt(newVal, key, {
    iv: iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC
  });
  const encryption = salt.toString() + iv.toString() + encryptedValue.toString();
  return encryption.toString();
  
}


  stringDecryption (transitmessage: any , encToken: any) {
    const keySize = 256;
    const iterations = 100;
    const newEnc = CryptoJS.enc.Utf8.parse(encToken);
    if(transitmessage.length > 0){
      var salt = CryptoJS.enc.Hex.parse(transitmessage.substr(0, 32));
      var iv = CryptoJS.enc.Hex.parse(transitmessage.substr(32, 32));
      var encrypted = transitmessage.substr(64);
      
      var key = CryptoJS.PBKDF2(newEnc, salt, {
          keySize: keySize/32,
          iterations: iterations
        });

      var decrypted = CryptoJS.AES.decrypt(encrypted, key, { 
        iv: iv, 
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC
        
      });
      
      // return decrypted;
      return decrypted.toString(CryptoJS.enc.Utf8)
    }
    
  }

  stringEncryptionV2(value: any, encToken: any) {
    const keySize = 256;
    const iterations = 100;
    const salt = CryptoJS.lib.WordArray.random(128 / 8);
    const newEnc = CryptoJS.enc.Utf8.parse(encToken);
    const key = CryptoJS.PBKDF2(newEnc, salt, {
      keySize: keySize / 32,
      iterations: iterations
    });
    const iv = CryptoJS.lib.WordArray.random(128 / 8);
    const newVal =  JSON.stringify(value);
    const encryptedValue = CryptoJS.AES.encrypt(newVal, key, {
      iv: iv,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC
    });
    const encryption = salt.toString() + iv.toString() + encryptedValue.toString();
    return encryption.toString();
    
  }
  encryption(encryptionValue: any,encToken:any) {
    if (
      encryptionValue == undefined ||
      encryptionValue == null ||
      encryptionValue == ''
    ) {
      return null;
    }
    let encryption_Value = this.stringEncryptionV2(encryptionValue,encToken);
    // Checking for the presence of '+' in encryption and regeneration if present
    checking: while (encryption_Value.indexOf('+') > -1) {
      encryption_Value = this.stringEncryptionV2(encryptionValue,encToken);
      continue checking;
    }
    checkingAgain: while (encryption_Value.indexOf(' ') > -1) {
      encryption_Value = this.stringEncryptionV2(encryptionValue,encToken);
      continue checkingAgain;
    }
    checkingAgain: while (encryption_Value.indexOf('/') > -1) {
      encryption_Value = this.stringEncryptionV2(encryptionValue,encToken);
      continue checkingAgain;
    }
    return encryption_Value;
  }
}