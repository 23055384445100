import { StorageService } from './storage.service';
import { apiConst } from './apiConst';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams, HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class ClaimAssistantService {

  baseUrl = environment.apiEnrolUrl;
  BucketValue = apiConst.bucdef;
  bucket_tariff = apiConst.bucpol;

  constructor(private storageService: StorageService,private http: HttpClient) { }

  getPreSignedUrlByKey(bucket, key): Observable<any> {
    const param = new HttpParams().set('bucket', bucket)
                                  .set('key',key);
    const httpOptions = {
      params: param
    };
    return this.http.get<any>(this.baseUrl + '/truecover/s3/get-pre-signed-url-by-key', httpOptions);
  }

  getObjectByKey(bucket, key): Observable<any> {
    const param = new HttpParams().set('bucket', bucket)
                                  .set('key',key);
    const httpOptions = {
      params: param
    };
    return this.http.get<any>(this.baseUrl + '/truecover/s3/get-s3-object-from-key', httpOptions);
  }

  saveIntimation(payload: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8'
      })
    };
    return this.http.post(this.baseUrl + '/truecover/submitClaimIntimation', payload,
      httpOptions
    );
  }
  submitClaim(payload: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8'
      })
    };
    return this.http.post(this.baseUrl + '/truecover/submitClaim', payload,
      httpOptions
    );
  }
  getClaimIntimation(memberId: any) {
    const param = new HttpParams().set('memberId', memberId);
    const httpOptions = {
      params: param
    };

    return this.http.get<any>(this.baseUrl + '/truecover/get-claim-intimation-history', httpOptions);
  }

  getClaimUrl() {
    return this.http.get<any>(this.baseUrl + '/truecover/get-claim-url');
  }

  getPreviousClaimStatus() {
    return this.http.get<any>(this.baseUrl + '/truecover/getClaimPushedDetails');
  }

  // get request
  get = (endpoint:string,paramsArg?:object):Observable<any> => {
    let params = new HttpParams();

    if(paramsArg) {
      for(let key of Object.keys(paramsArg)) {
        params = params.append(key,paramsArg[key]);
      }
    }

    let httpOptions = {
      params: params
    };

    return this.http.get<Observable<any>>(this.baseUrl + endpoint, httpOptions);
  }

  getOut = (endpoint:string,paramsArg?:object):Observable<any> => {

    let params = new HttpParams();

    if(paramsArg) {
      for(let key of Object.keys(paramsArg)) {
        params = params.append(key,paramsArg[key]);
      }
    }

    let httpOptions = {
      params: params
    };

    return this.http.get<Observable<any>>(endpoint, httpOptions);
  }

  // post request
  post = (endpoint:string,paramsArg?:object,data?:any):Observable<any> => {

    let params = new HttpParams();

    if(paramsArg) {
      for(let key of Object.keys(paramsArg)) {
        params = params.append(key,paramsArg[key]);
      }
    }

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8'
      }),
      params: params
    };

    return this.http.post<Observable<any>>(this.baseUrl + endpoint, data ? data : '',httpOptions);
  }

  postOut = (endpoint:string,paramsArg?:object,data?:any):Observable<any> => {

   
    let params = new HttpParams();

    if(paramsArg) {
      for(let key of Object.keys(paramsArg)) {
        params = params.append(key,paramsArg[key]);
      }
    }

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8'
      }),
      params: params
    };

    return this.http.post<Observable<any>>(endpoint, data ? data : '',httpOptions);
  }

  // post request
  put = (endpoint:string,paramsArg?:object,data?:any):Observable<any> => {

    let params = new HttpParams();

    if(paramsArg) {
      for(let key of Object.keys(paramsArg)) {
        params = params.append(key,paramsArg[key]);
      }
    }

    let httpOptions = {
      params: params
    };

    return this.http.put<Observable<any>>(this.baseUrl + endpoint, data ? data : '',httpOptions);
  }

  // delete request
  delete = (endpoint:string,paramsArg?:object):Observable<any> => {

    let params = new HttpParams();

    if(paramsArg) {
      for(let key of Object.keys(paramsArg)) {
        params = params.append(key,paramsArg[key]);
      }
    }

    let httpOptions = {
      params: params
    };

    return this.http.delete<Observable<any>>(this.baseUrl + endpoint, httpOptions);
  }
}
