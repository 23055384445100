import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { StorageService } from '../services/storage.service';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(
        private storageService: StorageService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler) {
        if (request.url == 'https://cdn-api.co-vin.in/api/v2/registration/certificate/public/download') {
            return from(this.handleCovid(request, next))
        }
        else if (request.url.includes('https://ifsc.razorpay.com/')) {
            return from(this.handleCovid(request, next))
        }
        else {
            return from(this.handle(request, next))
        }
    }

    async handle(req: HttpRequest<any>, next: HttpHandler) {
        // if your getAuthToken() function declared as "async getAuthToken() {}"
        let accessToken = await this.storageService.get('token');

        // if your getAuthToken() function declared to return an observable then you can use
        // await this.auth.getAuthToken().toPromise()

        const authReq = req.clone({
            setHeaders: {
                Authorization: `Token ${accessToken}`
            }
        });
        return next.handle(authReq).toPromise();
    }
    async handleCovid(req: HttpRequest<any>, next: HttpHandler) {
        const authReq = req.clone({

        });
        return next.handle(authReq).toPromise();
    }
}
