import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AbhaService } from 'src/app/core/services/abha.service';

@Component({
  selector: 'app-abha-enrol-member-details',
  templateUrl: './abha-enrol-member-details.component.html',
  styleUrls: ['./abha-enrol-member-details.component.scss'],
})
export class AbhaEnrolMemberDetailsComponent implements OnInit {
  allMembers: any=[];
  @ViewChild('widgetsContent') widgetsContent: ElementRef;
  constructor(
    private dialogRef: MatDialogRef<AbhaEnrolMemberDetailsComponent>,
    public abhaService: AbhaService,) { }

  ngOnInit() {
    this.getAbhaMember();
    console.log(this.allMembers);
  }
  getAbhaMember(){
    this.abhaService.getAbhaMember().subscribe((res: any) => {
      console.log(res);
     // if(res.status === 200){
        this.allMembers = res.body;
      //}
    });
    console.log(this.allMembers);
  }
  closeDialog(): void {
    this.dialogRef.close();
  }
}
