import { StorageService } from './../services/storage.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  isLoggedin = false;
  constructor(private storageService: StorageService, private router: Router) { }
  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    let token = await this.storageService.get('token');
    let tempToken = await this.storageService.get('temp_token');
    if (token) {
      return true;
    } else if (tempToken) {
      this.router.navigate(['/public/reset-password'], {
        queryParams: {
          firstTimeUser: true
        },
        skipLocationChange: true
      });

      return false;
    }
    this.router.navigate(['public']);
    return false;
  }
}
