import { PublicService } from 'src/app/core/services/public.service';
import { AuthenticationService } from './core/services/authentication.service';
import { Platform } from "@ionic/angular";
import { Component, Inject, OnInit } from "@angular/core";
import { SwUpdate, VersionReadyEvent } from "@angular/service-worker";
import { filter, map } from "rxjs/operators";
import { HomeService } from "src/app/core/services/home.service";
import { StorageService } from "./core/services/storage.service";
import { Idle, DEFAULT_INTERRUPTSOURCES } from "@ng-idle/core";
import { Keepalive } from "@ng-idle/keepalive";
import { interval, timer } from 'rxjs';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { Router } from "@angular/router";
import { HTTP } from "@ionic-native/http/ngx";
import { JailbreakRoot } from "@basecom-gmbh/capacitor-jailbreak-root-detection";
import { Capacitor, Plugins } from "@capacitor/core";
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
const { App } = Plugins;
declare var IRoot: any;
@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit {
  idleState = "Not started.";
  timedOut = false;
  lastPing?: Date = null;
  isIdle = false;
  dialogNotOpend = true;
  timerSubscription: any;
  logCount = 0;
  
  newAppVersion: any;
  currentAppVersion: any;
  constructor(
    swUpdate: SwUpdate,
    private homeService: HomeService,
    private storageService: StorageService,
    private idle: Idle,
    private keepalive: Keepalive,
    public dialog: MatDialog,
    private platform: Platform,
    private authService: AuthenticationService,
    private http: HTTP,
    private appVersion: AppVersion,
    private publicService: PublicService
  ) {
    this.checKAppVersion();
    this.timerSubscription = timer(0, (120 * 1000)).pipe( 
      map(async () => { 
        let userId = await this.storageService.get("userId");
        if (userId) {
          let expiryTime = await this.storageService.get("expiryTime");
          if (expiryTime) {
            var time = Date.now();
            if(time > expiryTime ){
              //   let refreshToekn = await this.storageService.get("refreshToken");
              //   this.homeService.refreshToken(refreshToekn).subscribe((newToken) => {
              //   this.storageService.set("token", newToken.body.accessToken);
              //   var expireTime = Date.now() + (1200 * 1000);
              //   this.storageService.set('expiryTime',expireTime);
              // });
            }
          };                              
        }
      }) 
    ).subscribe(); 
    const updatesAvailable = swUpdate.versionUpdates.pipe(
      filter((evt): evt is VersionReadyEvent => evt.type === "VERSION_READY"),
      map((evt) => ({
        type: "UPDATE_AVAILABLE",
        current: evt.currentVersion,
        available: evt.latestVersion,
      }))
    );
    updatesAvailable.subscribe(() => {
      if (confirm("New version available. Load New Version?")) {
        window.location.reload();
      }
    });
    if (typeof IRoot !== "undefined" && IRoot) {
      IRoot.isRooted(
        (data) => {
          if (data && data == 1) {
            navigator["app"].exitApp();
          } else {
          }
        },
        (data) => {
          console.log("routed device detection failed case", data);
        }
      );
    }
    this.idle.setIdle(300);

    // sets a timeout period of 30 seconds. after 10 minutes 30 seconds of inactivity, the user will be considered timed out.
    this.idle.setTimeout(20);

    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = "No longer idle.";
    });

    this.idle.onTimeout.subscribe(async () => {
      let userId = await this.storageService.get("userId");
      if (userId) {
        this.idleState = "Timed out!";
        this.timedOut = true;
        this.idle.stop();
        this.logout();
      }
    });

    this.idle.onIdleStart.subscribe(async () => {
      let userId = await this.storageService.get("userId");
      if (userId) {
        // console.log("You've gone idle!");
        this.idleState = "You've gone idle!";
        this.openDialog(this.idleState);
      }
    });

    this.idle.onTimeoutWarning.subscribe(async (countdown) => {
      let userId = await this.storageService.get("userId");
      if (userId) {
        this.idleState = "You will time out in " + countdown + " seconds!";
        // console.log(this.idleState);
        this.openDialog(this.idleState);
      }
    });

    this.idle.onInterrupt.subscribe((data) => {
      this.isIdle = false;
    });

    this.keepalive.interval(30);

    this.keepalive.onPing.subscribe(() => {
      this.idleState = "No longer idle.";
      this.lastPing = new Date();
    });

    // let userId = localStorage.getItem('userId');
    // if(userId) {
    this.idle.watch();
    this.timedOut = false;
    // } else {
    //   this.idle.stop();
    // }
    this.jailBreak();
    
  }

  ngOnInit() {
    this.reset();
  }
  openDialog(idleState): void {
    if(this.dialogNotOpend){
      const dialogRef = this.dialog.open(IdleSessionDialog, {
        data: idleState,
        disableClose: true,
      });
  
      dialogRef.afterClosed().subscribe((result) => {
        //console.log('The dialog was closed');
      });
      this.dialogNotOpend = false;
    }
    
  }
  jailBreakDialog(): void {
    const dialogRef = this.dialog.open(JailBreakingDialog, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      //console.log('The dialog was closed');
    });
  }
  async jailBreak() {
    let m = await JailbreakRoot.isJailbrokenOrRooted();
    if (m.result == true) {
      // navigator['app'].exitApp();
      this.jailBreakDialog();
    }
  }
  initializeApp() {
    this.platform.ready().then(() => {
      // console.log("[LOG] Platform Ready!");
      this.http
        .setServerTrustMode("pinned")
        .then(() => {
          console.log("[SUCCESS] SSL Pinning Starts!");
          if (Capacitor.isPluginAvailable("SplashScreen")) {
            Plugins.SplashScreen.hide();
            this.getTestURL();
          }
        })
        .catch(() => {
          console.log("[ERROR] SSL Pinning Fails!");
        });
    });
  }
  getTestURL() {
    this.http
      .get("https://reqres.in/api/users/2", {}, {})
      .then((data) => {
        console.log(data.status);
        console.log(data.data); // data received by server
        console.log(data.headers);
        console.log("pinning success");
        var responseData = JSON.parse(data.data);
        console.log("responseData", responseData);

        alert(responseData.data.first_name);
      })
      .catch((error) => {
        console.log(error.status);
        console.log(error.error); // error message as string
        console.log(error.headers);
        alert("SSL Pinning failed OR http req error");
      });
  }
  logout(){
    this.dialog.closeAll();
    this.authService.logout();
  }
  reset() {
    // we'll call this method when we want to start/reset the idle process
    // reset any component state and be sure to call idle.watch()
    this.idle.watch();
    this.idleState = "NOT_IDLE";
    
    this.lastPing = null;
  }
  checKAppVersion(){
    if(this.platform.is('android')){ 
      this.appVersion.getVersionNumber().then(value => {
        this.currentAppVersion = value.split('.');
        this.publicService.getMobileAppVersion().subscribe(
          (res: any) => {
            this.newAppVersion = res.body.split('.');
            if(this.newAppVersion[0] >  this.currentAppVersion[0]){
              this.versionDialog();
            } else if(this.newAppVersion[0] ==  this.currentAppVersion[0] && this.newAppVersion[1] >  this.currentAppVersion[1]){
              this.versionDialog();
            } else{

            }          
          },
          (err: any) => {
            // console.log(err);
          }
        );        
      }).catch(err => {
        // alert(err);
      });
    }
  }
  versionDialog(): void {
    const dialogRef = this.dialog.open(AndroidVersionDialog, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      //console.log('The dialog was closed');
    });
  }
}
@Component({
  selector: "idle-session-dialog",
  templateUrl: "idleSessionDialog.html",
})
export class IdleSessionDialog {
  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<IdleSessionDialog>,
    private homeService: HomeService,
    private storageService: StorageService,
    private authService: AuthenticationService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  async onNoClick(): Promise<void> {
    //   let refreshToekn = await this.storageService.get("refreshToken");
    //   this.homeService.refreshToken(refreshToekn).subscribe((newToken) => {
    //   this.storageService.set("token", newToken.body.accessToken);
    //   this.close();
    // });
    this.close();
  }
  logout(){
    this.close();
    this.authService.logout();
  }
  close() {
    this.dialogRef.close();
  }
}
@Component({
  selector: "jail-breaking-dialog",
  templateUrl: "jailBreakingDialog.html",
})
export class JailBreakingDialog {
  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<JailBreakingDialog>,
    private homeService: HomeService,
    private storageService: StorageService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
}

@Component({
  selector: "android-app-dialog",
  templateUrl: "androidVersionDialog.html",
})
export class AndroidVersionDialog {
  constructor(
    public dialogRef: MatDialogRef<AndroidVersionDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  
  downloadApp(){
    window.location.href = 'https://play.google.com/apps/testing/com.ewa.tpa';    
  }
}