import { EnrolmentGuard } from './core/guards/enrolment.guard';
import { AuthGuard } from './core/guards/auth.guard';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'gamification',
    loadChildren: () => import('./pages/gamification-v2/gamification-v2.module').then(m => m.GamificationV2Module),
    canActivate: [AuthGuard, EnrolmentGuard]
  },
  {
    path: 'landing',
    loadChildren: () => import('./pages/authenticated/landing.module').then(m => m.LandingPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'public',
    loadChildren: () => import('./pages/public/public.module').then(m => m.PublicModule)
  },
  {
    path: 'shared',
    loadChildren: () => import('./pages/authenticated/shared/shared.module').then(m => m.SharedModule)
  },
  {
    path: '',
    redirectTo: 'public',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  providers: [AuthGuard, EnrolmentGuard],
  exports: [RouterModule]
})
export class AppRoutingModule { }
