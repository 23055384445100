
import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, NgForm } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { HomeService } from '../core/services/home.service';
import { StorageService } from '../core/services/storage.service';
import { CommonModule } from '@angular/common';
import { AlertService } from 'src/app/core/services/alert.service';
@Component({
  selector: 'app-port-popup',
  templateUrl: './port-popup.component.html',
  styleUrls: ['./port-popup.component.scss'],
})
export class PortPopupComponent implements OnInit {
  memberId: any;
  currentURL: string;
  openWindow: boolean;
  showButton: boolean;
  selectedBaseMember: any;
  brokerId: any; 
  allInsurer: any=[];
  familyMemberNames : any=[];
  allCovers :any =[];
  @ViewChild('widgetsContent') widgetsContent: ElementRef;

  memberCoverInsurerForm : FormGroup= this.fb.group ({
    member: this.fb.array([]),
    cover: this.fb.array([]),
    insurer: this.fb.array([])
 });
  selectedMembers: any = [];
  selectedCover: any = [];
  selectedInsurer: any = [];
  constructor(
    private dialogRef: MatDialogRef<PortPopupComponent>,
    private homeService: HomeService,
    public storageService: StorageService,
    private fb : FormBuilder,
    private alertService : AlertService
  ) {}

  ngOnInit() {
    this.getProfile();
    this.checkPolicyCovers();
    this.getPolicyBrokers();
  }

 
  checkPolicyCovers() {

    this.homeService.getCorpPolicyDetails('health').subscribe(
      (res: any) => {
        if (res.status === 200) { 
          if(res.body.allCovers.allCovers.length > 0){
            this.allCovers = res.body.allCovers.allCovers;
          }
        }
      },
      (err: any) => {
        
      }
    );
   
    
  }

  getProfile() {
   this.homeService.getProfile().subscribe(
      (res: any) => {
        if (res.status === 200) {
          this.familyMemberNames = res.body.familyMembers;
          
        }
      },
      (err: any) => {
        // Handle error
      }
    );
    
    
  }

  getPolicyBrokers() {

    this.homeService.getPortingBrokerInsurer().subscribe(
      (res: any) => {
        if (res.status === 200) { 
          this.allInsurer=res.body;
          //console.log(res);
          this.brokerId=res.body[0].brokerId;
        }
      },
      (err: any) => {
        
      }
    );
   
    
  }
  
  scrollLeft(){
    this.widgetsContent.nativeElement.scrollLeft -= 150;
  }

  scrollRight(){
    this.widgetsContent.nativeElement.scrollLeft += 150;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
  onMemberChange(event:any){
     if(this.selectedMembers.find(element => element == event)){
      this.selectedMembers = this.selectedMembers.filter(item => item !== event);
     }else {
      this.selectedMembers.push(event);     
     }  
  }
  onCoverChange(event:any){
    if(this.selectedCover.find(element => element == event)){
      this.selectedCover = this.selectedCover.filter(item => item !== event);
     }else {
      this.selectedCover.push(event);     
     }     
  }

  onInsurerChange(event:any){
    if(this.selectedInsurer.find(element => element == event)){
      this.selectedInsurer = this.selectedInsurer.filter(item => item !== event);
     }else {
      this.selectedInsurer.push(event);     
     }
  }
  onFormSubmit(){
   //console.log(this.selectedCover);
   //console.log(this.selectedInsurer);
   //console.log(this.selectedMembers);
   let formData : any[]=[];
   for(var members of this.selectedMembers ){
      for (let index = 0; index < this.selectedCover.length; index++) {
        let element = this.selectedCover[index];
        let data = {
          'memberId':members,
          'policyId':element,
          'insurerId':this.selectedInsurer[0],
          'isActive':'true',
          'brokerId':this.brokerId
        }
        formData.push(data);
      }
  }
  this.homeService.postPortingMember(formData).subscribe(
    (res: any) => {
      if (res.status === 200) { 
        this.alertService.presentAlert('success', '', res && res.message ? res.message : 'Something went wrong!', 'success');
        this.dialogRef.close();
      }
    },
    (err: any) => {
      
    }
  );
   // console.log(form.controls['member'].value);
   // console.log(form.controls['cover'].value);
   // console.log(form.controls['insurer'].value);
  }
}
