import { Injectable } from '@angular/core';
import { AnimationController, ModalController, AlertController } from '@ionic/angular';
import { NotificationModalPage } from 'src/app/pages/public/components/shared/components/notification-modal/notification-modal.page';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(public modalController: ModalController, public alertController: AlertController,
    private animationCtrl: AnimationController) { }


  async createAlert(header, subHeader, message, buttons) {
    const alert = await this.alertController.create({
      header: header,
      subHeader: subHeader,
      message: message,
      buttons: buttons,
      translucent: true,
      backdropDismiss: false,
      enterAnimation: (baseEl: any, opts?: any) => {
        return(this.animationCtrl
          .create()
          .addElement(baseEl.querySelector('.alert-wrapper'))
          .duration(250)
          .keyframes([
            { offset: 0, opacity: '0' },
            { offset: 1, opacity: '1' }
          ]));
      },
      leaveAnimation: (baseEl: any, opts?: any) => {
        return(this.animationCtrl
          .create()
          .addElement(baseEl.querySelector('.alert-wrapper'))
          .duration(250)
          .keyframes([
            { offset: 0, opacity: '1' },
            { offset: 1, opacity: '0' }
          ]));
      },
    });

    await alert.present();
  }

  //Alert Function

  async presentAlert(title: any, subtitle: any, body: any, type: any) {
    const modal = await this.modalController.create({
      component: NotificationModalPage,
      cssClass: 'alertClass',
      componentProps: {
        title: title,
        subtitle: subtitle,
        alertBody: body,
        type: type
      },
      showBackdrop: true,
      backdropDismiss:false
    });
    return await modal.present();
  }

  async claimSuccessAlert(title: any, subtitle: any, body: any, type: any) {
    const modal = await this.modalController.create({
      component: NotificationModalPage,
      cssClass: 'claimSuccessClass',
      componentProps: {
        title: title,
        subtitle: subtitle,
        alertBody: body,
        type: type
      },
      showBackdrop: true,
      backdropDismiss:false
    });
    return await modal.present();
  }
}
