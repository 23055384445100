import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { ClaimAssistantService } from 'src/app/core/services/claim-assistant.service';
import { EncryptService } from 'src/app/core/services/encrypt.service';
import { StorageService } from 'src/app/core/services/storage.service';

@Component({
  selector: 'app-mobile',
  templateUrl: './mobile.page.html',
  styleUrls: ['./mobile.page.scss'],
})
export class MobilePage implements OnInit {
  
  subscription:Subscription = Subscription.EMPTY;

  title:string = "Register Mobile number";
  subtitle:string = "This will help us send confirmation, Member ID Cards & Claim information on your registered number";
  btnText:string = "Verify";
  btnText2: string = "Verify";
  isOTP:boolean = false;
  isOTP2: boolean = false;
  
  isHideClose: boolean = false;
  isOTPInp1Show: boolean = false;

  isLoading:boolean = false;
  isLoading2: boolean = false;

  verifiedPhone1: boolean = false;
  verifiedPhone2: boolean = false;

  mobile:FormControl = new FormControl(null,[
    Validators.required,
    Validators.minLength(10),
    Validators.maxLength(10),
    // Validators.pattern(/^[6-9]\d{9}/ig)
  ]);
  mobile2: FormControl = new FormControl(null, [
    Validators.required,
    Validators.minLength(10),
    Validators.maxLength(10),
    // Validators.pattern(/^[6-9]\d{9}/gi),
  ]);

  otp:FormGroup = this.fb.group({
    alpha: [null, [
      Validators.required,
      Validators.maxLength(1),
      Validators.minLength(1),
      Validators.min(0),
      Validators.max(9)
    ]],
    beta: [null, [
      Validators.required,
      Validators.maxLength(1),
      Validators.minLength(1),
      Validators.min(0),
      Validators.max(9)
    ]],
    gama: [null, [
      Validators.required,
      Validators.maxLength(1),
      Validators.minLength(1),
      Validators.min(0),
      Validators.max(9)
    ]],
    delta: [null, [
      Validators.required,
      Validators.maxLength(1),
      Validators.minLength(1),
      Validators.min(0),
      Validators.max(9)
    ]],
    omega: [null, [
      Validators.required,
      Validators.maxLength(1),
      Validators.minLength(1),
      Validators.min(0),
      Validators.max(9)
    ]],
    kappa: [null, [
      Validators.required,
      Validators.maxLength(1),
      Validators.minLength(1),
      Validators.min(0),
      Validators.max(9)
    ]]
  });
  otp2: FormGroup = this.fb.group({
    alpha2: [
      null,
      [
        Validators.required,
        Validators.maxLength(1),
        Validators.minLength(1),
        Validators.min(0),
        Validators.max(9),
      ],
    ],
    beta2: [
      null,
      [
        Validators.required,
        Validators.maxLength(1),
        Validators.minLength(1),
        Validators.min(0),
        Validators.max(9),
      ],
    ],
    gama2: [
      null,
      [
        Validators.required,
        Validators.maxLength(1),
        Validators.minLength(1),
        Validators.min(0),
        Validators.max(9),
      ],
    ],
    delta2: [
      null,
      [
        Validators.required,
        Validators.maxLength(1),
        Validators.minLength(1),
        Validators.min(0),
        Validators.max(9),
      ],
    ],
    omega2: [
      null,
      [
        Validators.required,
        Validators.maxLength(1),
        Validators.minLength(1),
        Validators.min(0),
        Validators.max(9),
      ],
    ],
    kappa2: [
      null,
      [
        Validators.required,
        Validators.maxLength(1),
        Validators.minLength(1),
        Validators.min(0),
        Validators.max(9),
      ],
    ],
  });

  isWhatsAppEnabled:FormControl = new FormControl(false);

  remainigTime:number = 60;
  remainigTime2: number = 60;

  interval:any;
  interval2: any;

  member:FormControl = new FormControl(null,Validators.required);
  member2: FormControl = new FormControl(null, Validators.required);

  constructor(private _dialogRef: MatDialogRef<any>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private fb:FormBuilder,
    private _snackBar: MatSnackBar,
    private claimAssistantService:ClaimAssistantService,
    private storageService: StorageService, private encrypt: EncryptService,) {
      if (this.data && this.data.title) {
        this.title = this.data.title;        
      }
      if (this.data && this.data.subtitle) {
        this.subtitle = this.data.subtitle;
      }
      if (this.data && this.data.btnText) {
        this.btnText = this.data.btnText;
      }
      if(this.data){
        // this.btnText2 = this.data.btnText;
        if (this.data.isHideClose) this.isHideClose = true;
      }
     }

  ngOnInit() {
    
  }


  validateEmployeeMobile(isResend?:boolean):void {
    if (isResend) this.remainigTime = 60;
    if (this.mobile.valid || isResend) {
      this.mobile.disable();
      if (!isResend) this.isLoading = true;
      this.subscription = this.claimAssistantService.post("/truecover/send-otp-callingNo", {callingNo:this.mobile.value}
        ).subscribe(
        (res:any):void => {
          this.mobile.enable();
          this.isLoading = false;
          this.isOTP = true;
          this.title = "OTP Verification";
          this.subtitle = `A one-time password has been sent\n to 91- ${this.mobile.value.substring(0, 4)}******`;
          this.btnText = "Submit";
          this._snackBar.open(res.message, "Ok", {
            duration: 4000
          });
          this.interval = setInterval(():void => {
            if(this.remainigTime <= 0) {
              this.remainigTime = 0;
              clearInterval(this.interval);
              this._snackBar.open("OTP expired try again or click on resend", "Ok", {
                duration: 4000
              });
            }
            else
              this.remainigTime -= 1;
          },1000);
        },
        (err:any):void => {
          this.mobile.enable();
          this.isLoading = false;
          let message:string = "Maybe you're offline or network issue";
          if (err && err.error && err.error.message)
            message = err.error.message;
          this._snackBar.open(message, "Ok", {
            duration: 4000
          });
        }
      );
    }
  }

  verifyOTP():void {
    if (this.otp.valid && this.isWhatsAppEnabled.valid) {
      const data:any = {
        otp: undefined,
        isWhatsAppEnabled: this.isWhatsAppEnabled.value,
        mobile: this.mobile.value,
        whatsAppMobile: this.isWhatsAppEnabled.value ? this.mobile.value : null,
      };
      data.otp = Object.values(this.otp.value).reduce((a:string,b:string):string => a + b);
      this.subscription.unsubscribe();
      this.isLoading = true;
      this.subscription = this.claimAssistantService
      // .post("/truecover/enroll/save-employee-mobile", {}, data)
      .post("/truecover/validate-otp-callingNo", {}, data)
      .subscribe(
        async (res:any):Promise<void> => {
          this.isLoading = false;
          clearInterval(this.interval);
          this._snackBar.open(res.message, "Ok", {
            duration: 4000
          });
          // await this.storageService.set("mv","true");
          // this._dialogRef.close({ data: 'success' });
          if (this.isWhatsAppEnabled.value) {
            localStorage.setItem("mv", "true");
            this._dialogRef.close({ data: "success" });
            this.saveMobileToDB(data);
          } else {
            this.saveMobileToDB2(data);
            this.verifyWhatsAppNumber();
          }
        },
        (err:any):void => {
          this.isLoading = false;
          let message:string = "Maybe you're offline or network issue";
          if (err && err.error && err.error.message)
            message = err.error.message;
          this._snackBar.open(message, "Ok", {
            duration: 4000
          });
        }
      );
    }
  }

  send(endpoint:string, data:any):void {
    this.isLoading = true;
    this.subscription = this.claimAssistantService.get(endpoint, data).subscribe(
      (res:any):void => {
        this.isLoading = false;
        this._snackBar.open(res.message, "Ok", {
          duration: 4000
        });
        this._dialogRef.close();
      },
      (err:any):void => {
        this.isLoading = false;
        let message:string = "Maybe you're offline or network issue";
        if (err && err.error && err.error.message)
          message = err.error.message;
        this._snackBar.open(message, "Ok", {
          duration: 4000
        });
      } 
    );
  }

  send2(endpoint: string, data: any): void {
    this.isLoading = true;
    this.subscription = this.claimAssistantService
      .get(endpoint, data)
      .subscribe(
        (res: any): void => {
          this.isLoading = false;
          this._snackBar.open(res.message, "Ok", {
            duration: 4000,
          });
          this._dialogRef.close();
        },
        (err: any): void => {
          this.isLoading = false;
          let message: string = "Maybe you're offline or network issue";
          if (err && err.error && err.error.message)
            message = err.error.message;
          this._snackBar.open(message, "Ok", {
            duration: 4000,
          });
        }
      );
  }

  async closeModal():Promise<void> {
    await this.storageService.set('mc','true');
    this._dialogRef.close(false);
  }
  submit():void {
    // console.log("s");
    if (this.isLoading === false) {
      switch(this.btnText) {
        case 'Verify': {
          this.validateEmployeeMobile();
          break;
        } 
        case 'Confirm': {
          this.validateEmployeeMobile();
          break;
        } 
        case "Share on whatsapp": {
          if (this.mobile.valid && this.member.valid) {
            const data:any = {
              // memberId:this.member.value.memberId,
              mobile: this.mobile.value,
              whatsAppMobile: this.isWhatsAppEnabled
                ? this.mobile.value
                : this.mobile2.value,
              // mobileOfMember:this.member.value.memberId,
              // providerBranchId: this.data.providerBranchId
              isWhatsAppEnabled: this.isWhatsAppEnabled,
            };
            this.send("/truecover/member/share-network-hospital-whatsapp", data);
          }
          break;
        }
        case "Send on whatsapp": {
          if (this.mobile.valid && this.member.valid) {
            // this.encrypt.stringEncryption(entity_id,'vote for NDM')
            const data:any = {
              memberId:this.encrypt.stringEncryption(this.member.value.memberId,'vote for NDM'),
              mobile:this.encrypt.stringEncryption(this.mobile.value,'vote for NDM'),
              mobileOfMember:this.encrypt.stringEncryption(this.data.memberId,'vote for NDM'),
            };
            this.send("/truecover/member/share-idcard-whatsapp-other", data);
          }
          break;
        }
      }
    }
  }

  changeMember():void {
    if (this.member.value && this.member.value.mobile)
      this.mobile.setValue(this.member.value.mobile);
    else
      this.mobile.setValue(null);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  validateEmployeeMobile2(isResend?: boolean): void {
    debugger;
    if (isResend) this.remainigTime2 = 60;
    // if (this.mobile.value == this.mobile2.value) {
    //   this._snackBar.open("Both the number are same", "Ok", {
    //     duration: 4000,
    //   });
    //   return;
    // }
    if (this.mobile2.valid || isResend) {
      this.mobile2.disable();
      if (!isResend) this.isLoading2 = true;
      this.subscription = this.claimAssistantService
        .post("/truecover/send-otp-whatsappNo", {
          whatsAppNo: this.mobile2.value,
        })
        .subscribe(
          (res: any): void => {
            this.mobile2.enable();
            this.isLoading2 = false;
            this.isOTP2 = true;
            this.title = "OTP Verification for whatsApp Number";
            this.subtitle = `A one-time password has been sent\n to 91- ${this.mobile2.value.substring(
              0,
              4
            )}******`;
            this.btnText2 = "Submit";
            this._snackBar.open(res.message, "Ok", {
              duration: 4000,
            });
            this.interval2 = setInterval((): void => {
              if (this.remainigTime2 <= 0) {
                this.remainigTime2 = 0;
                clearInterval(this.interval2);
                this._snackBar.open(
                  "OTP expired try again or click on resend",
                  "Ok",
                  {
                    duration: 4000,
                  }
                );
              } else this.remainigTime2 -= 1;
            }, 1000);
          },
          (err: any): void => {
            this.mobile.enable();
            this.isLoading2 = false;
            let message: string = "Maybe you're offline or network issue";
            if (err && err.error && err.error.message)
              message = err.error.message;
            this._snackBar.open(message, "Ok", {
              duration: 4000,
            });
          }
        );
    }
  }
  submit2(): void {
    // case : mob and whats are diff
    // return;
   
    debugger;
    console.log(this.btnText2);
    
    if (this.isLoading2 === false) {
      switch (this.btnText2) {
        case "Verify": {
          
          this.validateEmployeeMobile2();
          break;
        }
        case "Share on whatsapp": {
          if (this.mobile2.valid && this.member2.valid) {
            // this.verifiedPhone2 = true;
            const data: any = {
              // memberId: this.member2.value.memberId,
              mobile: this.verifiedPhone1 ? this.mobile : null,
              whatsAppMobile: this.mobile2.value,
              // mobileOfMember: this.member2.value.memberId,
              // providerBranchId: this.data.providerBranchId,
              isWhatsAppEnabled: this.isWhatsAppEnabled,
            };
            this.send(
              "/truecover/member/share-network-hospital-whatsapp",
              data
            );
          }
          break;
        }
        case "Send on whatsapp": {
          if (this.mobile2.valid && this.member2.valid) {
            const data: any = {
              memberId: this.member2.value.memberId,
              mobile: this.mobile2.value,
              mobileOfMember: this.data.memberId,
            };
            this.send("/truecover/member/share-idcard-whatsapp-other", data);
          }
          break;
        }
      }
    }
  }
  verifyOTP2(): void {
    if (this.otp2.valid && this.isWhatsAppEnabled.valid) {
      const data: any = {
        otp: this.otp2,
        isWhatsAppEnabled: this.isWhatsAppEnabled.value,
        whatsAppMobile: this.mobile2.value,
        mobile: this.verifiedPhone1 ? this.mobile.value : null,
      };
      data.otp = Object.values(this.otp2.value).reduce(
        (a: string, b: string): string => a + b
      );
      this.subscription.unsubscribe();
      this.isLoading2 = true;
      this.verifyWhatsappNumber(data);
    }
  }

  verifyWhatsAppNumber(): void {
    this.title = "Enter your whatsApp mobile number";
    this.subtitle =
      "Please update your contact number to stay updated about your Health Insurance / Card Details /Claims via Whatsapp / SMS";
    this.btnText = "Verify";
    this.verifiedPhone1 = true;
    this._snackBar.open("", "Ok", {
      duration: 4000,
    });
    // if (this.isWhatsAppEnabled.value) {
    //   localStorage.setItem("mv", "true");
    //   this._dialogRef.close({ data: "success" });
    // } else {

    // }
  }
  updateContactDetailsInDB(): void {
    localStorage.setItem("mv", "true");
    this._dialogRef.close({ data: "success" });
    let data;
    let url;
    if (!this.isWhatsAppEnabled.value) {
      data = {
        mobile: this.verifiedPhone1 ? this.mobile : null,
        whatsAppMobile: this.mobile2.value,
        isWhatsAppEnabled: this.isWhatsAppEnabled,
      };
      url = "/truecover/member/share-network-hospital-whatsapp";
    } else {
      data = {
        mobile: this.mobile.value,
        whatsAppMobile: this.isWhatsAppEnabled
          ? this.mobile.value
          : this.mobile2.value,
        isWhatsAppEnabled: this.isWhatsAppEnabled,
      };
      url = "/truecover/member/share-network-hospital-whatsapp";
    }
    this.send(url, data);
  }

  saveMobileToDB(data: any): void {
    this.subscription = this.claimAssistantService
      .post("/truecover/save-mobile-number", {}, data)
      .subscribe(
        (res: any): void => {
          this.isLoading = false;

          clearInterval(this.interval);
          this._snackBar.open(res.message, "Ok", {
            duration: 4000,
          });

          localStorage.setItem("mv", "true");
          this._dialogRef.close({ data: "success" });
        },
        (err: any): void => {
          this.isLoading = false;
          let message: string = "Maybe you're offline or network issue";
          if (err && err.error && err.error.message)
            message = err.error.message;
          this._snackBar.open(message, "Ok", {
            duration: 4000,
          });
          // this.verifyWhatsAppNumber();
        }
      );
  }

  saveMobileToDB2(data: any): void {
    this.subscription = this.claimAssistantService
      .post("/truecover/save-mobile-number", {}, data)
      .subscribe(
        (res: any): void => {
          this.isLoading = false;

          clearInterval(this.interval);
          this._snackBar.open(res.message, "Ok", {
            duration: 4000,
          });

          localStorage.setItem("mv", "true");
          // this._dialogRef.close({ data: "success" });
        },
        (err: any): void => {
          this.isLoading = false;
          let message: string = "Maybe you're offline or network issue";
          if (err && err.error && err.error.message)
            message = err.error.message;
          this._snackBar.open(message, "Ok", {
            duration: 4000,
          });
          // this.verifyWhatsAppNumber();
        }
      );
  }

  verifyWhatsappNumber(data: any) {
    this.subscription = this.claimAssistantService
      .post("/truecover/validate-otp-whatsAppNo", {}, data)
      .subscribe(
        (res: any): void => {
          this.isLoading2 = false;
          clearInterval(this.interval2);
          this._snackBar.open(res.message, "Ok", {
            duration: 4000,
          });
          this.saveMobileToDB(data);
        },
        (err: any): void => {
          this.isLoading2 = false;
          let message: string = "Maybe you're offline or network issue";
          if (err && err.error && err.error.message)
            message = err.error.message;
          this._snackBar.open(message, "Ok", {
            duration: 4000,
          });
        }
      );
  }
  numberOnly(event:any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
