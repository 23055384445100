
import { Component, HostListener, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { HomeService } from 'src/app/core/services/home.service';
import { Utility } from 'src/app/core/services/utils';
import { StorageService } from 'src/app/core/services/storage.service';
import { AlertService } from 'src/app/core/services/alert.service';

@Component({
  selector: 'app-change-primary-email',
  templateUrl: './change-primary-email.page.html',
  styleUrls: ['./change-primary-email.page.scss'],
})
export class ChangePrimaryEmailPage implements OnInit {

  Form: FormGroup;
  isSubmitted = false;
  message: string;
  success = false;
  cc: any;
  mobile: any;
  invalid_otp: any;
  invalid_email: any;
  email: any;
  Utility = new Utility();
  userID: any;
  constructor(private dialogRef: MatDialogRef<ChangePrimaryEmailPage>,
    private homeService: HomeService,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private storageService: StorageService,) { }

  @HostListener('contextmenu', ['$event'])
  onrightclick(e: MouseEvent){
    e.preventDefault();
  };

  ngOnInit() {
    this.Form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      otp: ['', [Validators.required, Validators.pattern('[0-9]{6,6}')]],
    });
  }
  async getUserId(){
    this.userID = await this.storageService.get('userId');
  }
  get email_id() {
    return this.Form.get('email_id') as FormArray;
  }
  // Mobile Number Input Validations
  get form() { return this.Form.controls; }
  validation() {
    this.isSubmitted = true;
    if (this.Form.invalid) {
      return;
    }
  }
  close() {
    this.dialogRef.close();
  }
  sendOTP(form: any): void {
    if(this.form.email.errors){
      return;
    }

    if (form.value.email !== '') {
      const encryptedEmail = this.Utility.encryption(form.value.email);
      this.homeService.AddEmail(encryptedEmail).subscribe(
        (result) => {
          if (result.status === 200) {
            // this.dataservice.currentMessage.subscribe(message => this.message = message);
            this.success = true;
            this.alertService.presentAlert('Success!', '', 'OTP has been sent', 'success');     
            
          }
        },
        err => {
          if (err.status === 400) {
            this.invalid_email = err.error.message != null ? err.error.message : 'Oops! Something went wrong';
          } else {
            
          }
        }
      );
    }
  }
  otp(form: any) {
    if(this.success){
      this.homeService.ValidateOtpPrimaryEmail(form.value.otp, this.Utility.encryption(form.value.email), this.userID).subscribe(
        (result) => {
          if (result.status === 200) {
            const dialogRef1 = this.dialogRef.close({
              email: form.value.email,
              email_id: result.body.id,
              maskEmail: result.body.maskEmail
            });
          }
        },
        err => {
          if (err.status === 400) {
            this.invalid_otp = err.error.message != null ? err.error.message : 'Oops! Something went wrong';
          } else {
            
          }
        }
      );
    }else{

    }

  }
}
