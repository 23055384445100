import { apiConst } from './apiConst';
import * as CryptoJS from 'crypto-js';
export class Utility {
  constructor() {}
  encryption(encryptionValue: any) {
    if (
      encryptionValue == undefined ||
      encryptionValue == null ||
      encryptionValue == ''
    ) {
      return null;
    }
    let encryption_Value = this.set(encryptionValue);
    // Checking for the presence of '+' in encryption and regeneration if present
    checking: while (encryption_Value.indexOf('+') > -1) {
      encryption_Value = this.set(encryptionValue);
      continue checking;
    }
    checkingAgain: while (encryption_Value.indexOf(' ') > -1) {
      encryption_Value = this.set(encryptionValue);
      continue checkingAgain;
    }
    return encryption_Value;
  }
  displayName(value: any) {
    let displayValue =
      value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
    displayValue = displayValue.split('_').join(' ');
    return displayValue;
  }
  calculateAge(dob: any) {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }
  indianRupeeFormat(amount: any) {
    let x = amount;
    x = x.toString();
    let lastThree = x.substring(x.length - 3);
    let otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers != '') {
      lastThree = ',' + lastThree;
    }
    let res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree;
    return res;
  }
  checkForEmail(userName: any) {
    // Checking if the given username is an email
    // tslint:disable-next-line: max-line-length
    const reEmail = /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/.test(
      userName
    );
    return reEmail;
  }
  checkForMobile(userName: any) {
    // Checking if the given username contains 10 numbers i.e mobile
    const reMobile = /^\d{10}$/.test(userName);
    return reMobile;
  }
  checkForOTP(passwordORotp: any) {
    // Regex for Otp which must be of length 6 and must contain only number
    const reOTP = /^\d{6}$/.test(passwordORotp);
    return reOTP;
  }
  checkForPassword(passwordORotp: any) {
    // Regex for Password which must be of length 8, must contain one capital letter, 1 special character and 1 number.
    const rePassword = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(
      passwordORotp
    );
    return rePassword;
  }
  enumFormat(value: any) {
    let enumType = value.toUpperCase();
    enumType = enumType.replace(' ', '_');
    return enumType;
  }
  set(value) {
    const keySize = 256;
    const iterations = 100;
    const salt = CryptoJS.lib.WordArray.random(128 / 8);
    const key = CryptoJS.PBKDF2(apiConst.encdef, salt, {
      keySize: keySize / 32,
      iterations: iterations,
    });
    const iv = CryptoJS.lib.WordArray.random(128 / 8);
    const uid = CryptoJS.AES.encrypt(value, key, {
      iv: iv,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
    });
    const userId = salt.toString() + iv.toString() + uid.toString();
    return userId;
  }

  fixDateToUTC(date: Date): Date {
    if (date) {
      const newDate = new Date(date);
      const dateFix = new Date(
        Date.UTC(
          newDate.getFullYear(),
          newDate.getMonth(),
          newDate.getDate(),
          0,
          0,
          0,
          0
        )
      );
      return dateFix;
    } else {
      return null;
    }
  }
}
