import { StorageService } from './../services/storage.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class EnrolmentGuard implements CanActivate {

  constructor(
    private storageService: StorageService,
    private _router: Router
  ) {}

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
      let user_screen = await this.storageService.get('user_screen');
    if(user_screen) {
      if(user_screen && parseInt(user_screen) > 0) {
        return true;
      } else {
        this._router.navigate(['/landing']);
        return false;
      }
    } else {
      this._router.navigate(['/public']);
      return false;
    }
  }
}
