import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Platform } from '@ionic/angular';
import { HostListener } from '@angular/core';

@Component({
  selector: 'app-notification-modal',
  templateUrl: './notification-modal.page.html',
  styleUrls: ['./notification-modal.page.scss'],
})
export class NotificationModalPage implements OnInit {

  @Input() title: any;
  @Input() subtitle: any;
  @Input() alertBody: any;
  @Input() type: any;
  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    if (this.type === 'claimSuccess') {
      this.dismiss();
      // console.log('pop');
      this.router.navigate['/landing/claims/claim-list'];
    }
  }

  constructor(
    public modalController: ModalController,
    private platform: Platform,
    private router: Router,
  ) { }

  ngOnInit() {
    this.platform.backButton.subscribeWithPriority(10, () => {
      if (this.type === 'claimSuccess') {
        this.dismiss();
        this.router.navigateByUrl('/landing/claims/claim-list');
      }
      // console.log('Handler was called!');
    });
  }

  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  deleteMember() {
    this.modalController.dismiss({
      'dismissed': true,
      action: 'delete'
    });
  }

  trackStatus() {
    this.modalController.dismiss({
      'dismissed': true,
    });
    this.router.navigateByUrl('/landing/claims/claim-list');
  }
  goToHomePage() {
    this.modalController.dismiss({
      'dismissed': true,
    });
    this.router.navigateByUrl('/landing/home');
  }
}
