export const apiConst = {
  encdef: 'vote for NDM',
  bucdef: 'uat-ewa-corporate-policy-docs',
  bucpol: 'uat-ewa-member-uploaded-policies',
  conpho: 'uat-ewa-consumer-photographs',
  bandef: 'uat-ewa-banner-bucket',
  clidef: 'EWA',
  cladoc: 'uat-ewa-claim-docs',
  idtdoc: 'uat-ewa-tempidcard',
};
